function initMap() {
        // colours
        var darkgrey = '#3a363c';
		var lightgrey = '#e8e8e8';
		var whitesmoke = '#f5f5f5';
		var grey = '#5e5e5e';
		var blue = '#5fd4ff';
		var red = '#ff917f';
		var yellow = '#ffdc62';
		var green = '#00ffb0';
		var purple = '#c5a3ff';

		//location
        var motum = {lat: 43.653328, lng: -79.369760};

        //map and settings
        var map = new google.maps.Map(document.getElementById('motum-map'), {
          center: motum,
          zoom: 15,
          //styles
          styles: [
            {elementType: 'geometry', stylers: [{color: whitesmoke}]},
            {elementType: 'labels.text.stroke', stylers: [{color: whitesmoke}]},
            {elementType: 'labels.text.fill', stylers: [{color: red}]},
            {
              featureType: 'administrative.locality',
              elementType: 'labels.text.fill',
              stylers: [{color: darkgrey}]
            },
            {
              featureType: 'poi',
              elementType: 'labels.text.fill',
              stylers: [{color: darkgrey}]
            },
            {
              featureType: 'poi.park',
              elementType: 'geometry',
              stylers: [{color: green}]
            },
            {
              featureType: 'poi.park',
              elementType: 'labels.text.fill',
              stylers: [{color: grey}]
            },
            {
              featureType: 'road',
              elementType: 'geometry',
              stylers: [{color: lightgrey}]
            },
            {
              featureType: 'road',
              elementType: 'geometry.stroke',
              stylers: [{color: whitesmoke}]
            },
            {
              featureType: 'road',
              elementType: 'labels.text.fill',
              stylers: [{color: '#9ca5b3'}]
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry',
              stylers: [{color: grey}]
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry.stroke',
              stylers: [{color: whitesmoke}]
            },
            {
              featureType: 'road.highway',
              elementType: 'labels.text.fill',
              stylers: [{color: darkgrey}]
            },
            {
              featureType: 'transit',
              elementType: 'geometry',
              stylers: [{color: yellow}]
            },
            {
              featureType: 'transit.station',
              elementType: 'labels.text.fill',
              stylers: [{color: darkgrey}]
            },
            {
              featureType: 'water',
              elementType: 'geometry',
              stylers: [{color: blue}]
            },
            {
              featureType: 'water',
              elementType: 'labels.text.fill',
              stylers: [{color: grey}]
            },
            {
              featureType: 'water',
              elementType: 'labels.text.stroke',
              stylers: [{color: blue}]
            },
            {
			    featureType: "poi",
			    stylers: [
			      { visibility: "off" }
			    ]   
		  }
          ]
        });
        var marker = new google.maps.Marker({
          position: motum,
          map: map,
          icon: '/images/interface/map-pin.png' 
        });
      }















