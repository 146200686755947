'use strict';

$(document).ready(function(){

    $(document).on('click', '.js-toggle-mobile-nav', function(e){
        $(this).toggleClass('active');
        $('.main-nav').fadeToggle().css('display', 'flex');
        $('body').toggleClass('mobile-menu-open');
    });

    $(document).on('click', '.header a, .mobile-nav a', function(e){
        $('.menu-toggle').removeClass('active');
        $('.main-nav li').removeClass('active');
        $('.mobile-nav li').removeClass('active');
        $(this).parent().addClass('active');
    });

    $(document).on('click', '.specialties-nav a', function(e){
        $('.specialties-nav li').removeClass('active');
        $(this).parent().addClass('active');
    });

    $(document).on('click', '.js-scroll-to', function(e){
        var scrollTarget = $(this).attr('scroll-to');
        $('html, body').animate({
            scrollTop: $('.'+scrollTarget).offset().top
        }, 1000);
    });

    $(document).on('click', '.js-sticky-scroll-to', function (e){
        var scrollTarget = $(this).attr('scroll-to');
        var scrollOffset = largerScreen.matches ? 180 : 50;
        $('html, body').animate({
            scrollTop: $(scrollTarget).offset().top - scrollOffset
        }, 500);
    });


    // ** Nav disappearing bug fix when browser width is adjusted **
    // --- 
    // Anything larger than tablet width (768px + 1px) (AKA desktop nav):
    const largerScreen = window.matchMedia("(min-width: 769px)");
    
    const adjustNav = function(largerScreen) {
        const menuToggle = document.querySelector(".menu-toggle");
        const pageBody = document.querySelector("body");
        const mainNav = document.querySelector('.main-nav');
        if (largerScreen.matches){
        // At larger screen width: 
            // show nav even after mobile nav has been closed (no more disappearing)
            mainNav.style.display = "flex";
            menuToggle.style.display = "none";
            // remove dark background overlay
            pageBody.classList.remove("mobile-menu-open");
        } 
        else {
        // At smaller screen width: 
            // re-hide mobile nav links and display menu toggle button
            if(mainNav.style.display === "flex") {
                mainNav.style.display = "none";
                menuToggle.style.display = "block";
            }
            // if menu was already open at mobile screen width, continue to have it open
            if (menuToggle.classList.contains("active")) {
                mainNav.style.display = "flex";
                pageBody.classList.add("mobile-menu-open");
            }
        } 
    }
    
    largerScreen.addEventListener('change', () => {
        adjustNav(largerScreen);
    })



    //BLOG PAGE STICKY ARTICLE NAV
    // init the observer
    
    const changeNav = (entries => {
        entries.forEach((entry) => {
            const id = entry.target.getAttribute('id');
            let blogNavLink = document.querySelector(`.blog-sticky-nav button[scroll-to="#${id}"]`);
            if (blogNavLink){
                if (entry.isIntersecting && entry.intersectionRatio > 0) {
                    blogNavLink.parentElement.classList.add('active');
                } else {
                    blogNavLink.parentElement.classList.remove('active');
                }
            }
        });
    });

    const options = {
        rootMargin: "-45% 0px -55% 0px",
        //threshold: 1,
    };

    const blogObserver = new IntersectionObserver(changeNav, options);


    //BLOG PAGE VIDEO AUTO PLAY
    const videoObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.intersectionRatio > 0) {

                entry.target.play()
            } else {
                entry.target.pause()
            }
        });
    });
    


    // Run animation preloader
    var loaderAnimation = bodymovin.loadAnimation({
        container: document.getElementById('transition-loader'), // Required
        path: '/animation_data/loader/data.json', // Required
        renderer: 'svg', // Required
        loop: true, // Optional
        autoplay: false // Optional
    });




    //import Swup from 'swup';
    const swup = new Swup({
      //  debugMode: true,
        containers: ["#swup", '#header'],
        cache: true,
        animateHistoryBrowsing: true,
        plugins: [
            new SwupBodyClassPlugin(),
            new SwupScrollPlugin(),
            new SwupPreloadPlugin(),
            new SwupA11yPlugin({
                contentSelector: 'body',
            }),
            new SwupProgressPlugin({
                className: 'swup-progress-bar',
                transition: 1000,
                delay: 300
            }),
            new SwupScriptsPlugin(),
            // new SwupDebugPlugin()
        ],
        linkSelector:
            'a[href^="' + window.location.origin +'"]:not([data-no-swup]):not([target="_blank"]), ' +
            'a[href^="/"]:not([data-no-swup]):not([target="_blank"]), ' +
            'a[href^="#"]:not([data-no-swup]):not([target="_blank"])'
    });
 
    
    // Add is-visible class on load, and after transition
    $('body').addClass('is-visible');
    $('html').addClass('first-load');


    // Remove initial is-animating class to trigger animations on page load
    $('html').removeClass('is-animating');
    swup.on('contentReplaced', function() {
        $('body').addClass('is-visible');
    });

    swup.on('animationOutDone', function() {
      //  alert('pageloaded');
    });
    swup.on('animationOutStart', function() {
        loaderAnimation.stop();
        setTimeout(function(){
            loaderAnimation.play();
        }, 300);
        $('body').removeClass('is-visible');

    });
    swup.on('pagePreloaded', function() {

        // console.log($('.specialty-header').outerHeight());
        $('.specialty-header-placeholder').css('height', $('.specialties-index-wrapper').outerHeight());
    });





    // Run init function initially
    init();
    // Run init on each page load
    swup.on('contentReplaced', init);

    

    function unload() {
        //  if ($('[src^="https://maps.googleapis.com/maps"]').length){
        //     $('[src^="https://maps.googleapis.com/maps"]').each(function(){
        //         $(this).remove();
        //     })
        //  }
        if ($('.blog-sticky-nav')[0]){
            removeStickyBlogNav()
        }

        //for blog video play on scroll 
        if ($('.video-block__video--play-on-scroll')[0]){
            removePlayVideoOnScroll()
        }

    }
    
    swup.on('willReplaceContent', unload);


    function init() {
  
        // autorun fancybox
        /**** 
         * added autoStart: false -WM ****/
        $('[data-fancybox]').fancybox({
            keyboard: true,
            preventCaptionOverlap: false,
            infobar: false,
            buttons: [
                "thumbs",
                "zoom",
                "close"
            ],
            idleTime: 300,
            touch:{
                vertical : false,
                momentum : false
            },
            clickContent:false,
            animationEffect:"fade",
            hash: false,
            slideShow: {
                autoStart: false,
                speed: 3000
            },
        });

        // autorun tilt
        $('.js-tilt').tilt({
            glare: false,
            maxGlare: .5,
            maxTilt:        3,
            scale:          1.02,
            speed:          1000,
        })

        // Init Animate on Scroll Library
        AOS.init({
            // Global settings:
            disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
            startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
            initClassName: 'aos-init', // class applied after initialization
            animatedClassName: 'is-in-view', // class applied on animation
            useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
            disableMutationObserver: false, // disables automatic mutations' detections (advanced)
            debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
            throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


            // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
            offset: 120, // offset (in px) from the original trigger point
            delay: 0, // values from 0 to 3000, with step 50ms
            duration: 400, // values from 0 to 3000, with step 50ms
            easing: 'ease', // default easing for AOS animations
            once: false, // whether animation should happen only once - while scrolling down
            mirror: false, // whether elements should animate out while scrolling past them
            anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

        });


        // var activePageSlug = $('#active-page-slug').attr('slug');
        // $('.navigation li').removeClass('active');
        // $('.navigation li[slug="'+activePageSlug+'"]').addClass('active');


        var scrollThrottleTimeout;
        $(window).bind(
            'scroll',
            function()
            {
                if (!scrollThrottleTimeout) {
                    scrollThrottleTimeout = setTimeout(
                        function()
                        {
                            //  console.log('test');
                            onPageScroll();
                            scrollThrottleTimeout = null;
                        },
                        100
                    );
                }
            }
        );

        var prevScrollpos = window.pageYOffset;
        function onPageScroll(){
            // console.log("Scrolled");
            if ($(window).scrollTop() >= 300) {
                $('.header').addClass('header--is-fixed');
                $('body').addClass('header-fixed');
            } else if ($(window).scrollTop() == 0) {
                $('.header').removeClass('header--is-fixed');
                $('body').removeClass('header-fixed');
            }

            window.onscroll = function() {
                var currentScrollPos = window.pageYOffset;
                if (prevScrollpos > currentScrollPos) {
                    $('.header').removeClass('header--is-hidden');
                } else if (currentScrollPos > 200) {
                    $('.header').addClass('header--is-hidden');
                }
                prevScrollpos = currentScrollPos;
            }

            if ($(window).scrollTop() >= 500) {
                $('.social-share-buttons').addClass('fixed');
            } else {
                $('.social-share-buttons').removeClass('fixed');
            }

            if ($('.blog--has-sticky-nav')){
                let imgHeight = $('.post-header__img')[0] ? $('.post-header__img').height() : 0;
                if ($(window).scrollTop() >= (450 + imgHeight)) {
                    $('.social-share-buttons').addClass('sticky-nav--fixed');
                } else {
                    $('.social-share-buttons').removeClass('sticky-nav--fixed');
                }
            }


            if ($('.subspecialty-rows')[0]){
                if ($(window).scrollTop() >= ($('.subspecialty-rows').position().top + 100)) {
                    $('.specialty-fixed-header').addClass('is-visible');
                }  else {
                    $('.specialty-fixed-header').removeClass('is-visible');
                }
            }
        }

        if (document.querySelector('#team-line')) {
            initTeamPage();
            // something like new Carousel('#carousel')
        }

        if (document.querySelector('#our-work-index')) {
            initOurWorkIndex();
        }

        if ($('.our-work-card')[0]) {
            initOurWorkCard();
        }

        // if (document.querySelector('#intro-video')) {
        //     initHomeVideo();
        // }

        if ($('.project-header__visual')[0]) {
            initProjectViewCarousel();
        }

        if ($('.social-share-buttons')[0]) {
            initSocialShareButtons();
        }
        if ($('.client-list__slideshow')[0]) {
            initClientListSlideshow();
        }
        if ($('.testimonial__slideshow')[0]) {
            initClientTestimonialSlideshow();
        }
        if ($('.thinking-side-content__slideshow')[0]) {
            initThinkingSideContentSlideshow();
        }
        if ($('.process-sections')[0]) {
            initProcessSVG();
        }

        if ($('form #country-input')[0]){
            initCountryOptin();
        }

        if ($('.specialties-index-card')[0]) {
            $('.specialties-index-card').click(function(){
                $(this).addClass('is-active');

                // Reposition transition circle to card
                var left = $(this).offset().left + ($(this).outerWidth() / 2);
                var top = $(this).offset().top + ($(this).outerHeight() / 2);
                $('.specialty-header-placeholder__inner').css('left', left).css('top', top);
            });
        }

        if ($('.blog-sticky-nav')[0]){
            initStickyBlogNav()
        }

        //blog video play on scroll
        if ($('.video-block__video--play-on-scroll')[0]){
            initPlayVideoOnScroll()
        }


        //INIT BAMBOO CAREERS 
        //This still necessary as swup scripts plugin doesn't help the document.write issue with this script 
        if (document.querySelector('#BambooHR')) {
            // Used the relevant code from https://motumb2b.bamboohr.com/js/jobs2.php 
            var embedUrl = "https://motumb2b.bamboohr.com/jobs/embed2.php";

            var xmlhttp;
            var ieFlag = 0;
            if (('XDomainRequest' in window && window.XDomainRequest !== null) && document.documentMode < 10) {
                xmlhttp=new XDomainRequest();
                ieFlag = 1;
            } else if (window.XMLHttpRequest) {
                xmlhttp=new XMLHttpRequest();
            } else {
                xmlhttp=new ActiveXObject("Microsoft.XMLHTTP");
            } 

            function loadd() { //needed for IE9 CORS
                var content = xmlhttp.responseText;
                var footerId="BambooHR-Footer";
                var fel=document.getElementById(footerId);

                var divId="BambooHR-ATS";
	            var el=document.getElementById(divId);
        
                el=document.getElementById(divId);

                el.innerHTML=content;
            }

            try{
                xmlhttp.onload = loadd;
                xmlhttp.open("GET",embedUrl);
                xmlhttp.send();
            } catch(e){
                console.log(e)
            }    
        }

        // Stop Swup from running on BambooHR links that are dynamically added
        var bambooHR = document.querySelector('#BambooHR-ATS');
        var bamboo_config = { childList: true, subtree: true };

        var bamboo_callback = function() {
            document.querySelectorAll("#BambooHR a").forEach( anchor => {
                anchor.setAttribute('data-no-swup', true)
                anchor.setAttribute('target', "_blank")
            })
        };

        var searchObserverBamboo = new MutationObserver(bamboo_callback);
    
        if (bambooHR && bambooHR.nodeType === Node.ELEMENT_NODE){
            searchObserverBamboo.observe(document.getElementById('BambooHR'), bamboo_config);
        } 
        //if it loads before this code runs, catch it here
        if (document.querySelector(".BambooHR-ATS-Jobs-List")){
            document.querySelectorAll("#BambooHR a").forEach( anchor => {
                //console.log("bamboo clean");
                anchor.setAttribute('data-no-swup', true)
                anchor.setAttribute('target', "_blank")
            })
        }	

        //INIT GENERAL CONTACT
        if (document.querySelector('#generalContact')){
            //find checkbox by name
            $('.checkboxes input[type=checkbox].js-im-interested-in-hidden').on('change', function() {
                var InterestedInValues = $('.checkboxes input[type=checkbox]:checked').map(function() {
                    return $(this).val();
                }).get().join(', ');
                //console.log(InterestedInValues);
                //add the values to the hidden field
                $('#form-input-imInterestedInHidden').val(InterestedInValues);
            
            });
        }
        // if (document.querySelector('#stayInformed')){
        //     initAOForm('stayInformed', '0014')
        // }

    } 

//     function initAOForm(formID, fid){
//         // console.log("running initAOForm")
//         /* Act-On Form Information */
//         //http://a9952.actonsoftware.com/acton/form/9952/001b:d-0001/0/-/-/-/-/index.htm
//         //http://[server]/acton/form/[aid]/[fid]:[did]/0/index.htm
//         var aoProtocol = location.protocol;
//         if ( aoProtocol.indexOf('http') < 0 ) aoProtocol = 'http:';
//         var aoCAP = {
//             aid: '9952',
//             fid: fid,
//             did: 'd-0001',
//             server: 'a9952.actonsoftware.com',
//             formId: formID,
//             protocol: aoProtocol
//         };
//         var aoArr = aoArr || []; aoArr.push(aoCAP);
//         /* --------- */

//         // If form is valid, set variable so Act-On script can do its job
//         // formElement.validator().on('submit', function (e) {
//         //     if (e.isDefaultPrevented()) {
//         //         formIsValid = false;
//         //     } else {
//         //         formIsValid = true;
//         //     }
//         // });
//         function AoProcessForm(formelement) {
//             for (AoI = 0; AoI < aoArr.length; AoI++) {
//                 if ( aoArr[AoI].aid && aoArr[AoI].fid && aoArr[AoI].did && aoArr[AoI].server && ( aoArr[AoI].formId || aoArr[AoI].formName )) {
//                     var d = document,
//                         thisFormId = formelement.id || '',
//                         thisFormName = formelement.name || '',
//                         bi = function(i) {
//                             return d.getElementById(i)
//                         },
//                         bn = function(i) {
//                             return d.getElementsByName(i)[0]
//                         },
//                         names = [],
//                         values = [],
//                         params = {},
//                         w = window,
//                         targetIdOrName = aoArr[AoI].formName ? bn(aoArr[AoI].formName) : bi(aoArr[AoI].formId),
//                         len = targetIdOrName.elements.length,
//                         isLoaded = false,
//                         ud = 'undefined',
//                         st = function(f, i) {
//                             w.setTimeout(f, i)
//                         },
//                         ce = function(t) {
//                             return d.createElement(t)
//                         },
//                         gid = function(p) {
//                             var j, i = 0,
//                                 n = Math.random,
//                                 r = [],
//                                 c = '0123456789abcdef'.split('');
//                             for (; i < 16; i++) r[i] = c[(0 | n() * 16) & 0xf];
//                             j = p + r.join('');
//                             return bi(j) == null ? j : gid(p);
//                         },
//                         addInput = function( form, name, value )
//                     {
//                         var el = ce('input');
//                         el.name = name;
//                         el.value = value;
//                         form.appendChild( el );
//                     },
//                         idifr = gid('aoCapT');
//                     if (aoArr[AoI].formName == thisFormName || aoArr[AoI].formId == thisFormId) {
//                         var dTarget = ce('div');
//                         dTarget.style.display = 'none';
//                         d.body.appendChild(dTarget);
//                         dTarget.innerHTML = '<iframe name="' + idifr + '" id="' + idifr + '"><\/iframe>'; // generate iframe
//                         var dForm = ce('form'), idform = gid('aoCapD');
//                         dForm.id = idform;
//                         dForm.style.display = "none";
//                         dForm.method = 'POST';
//                         dForm.enctype = 'multipart/form-data';
//                         dForm.acceptCharset = 'UTF-8';
//                         dForm.target = idifr; // form targets iframe
//                         dForm.action = (aoCAP.protocol || w.location.protocol) + '//' + aoCAP.server + '/acton/forms/userSubmit.jsp';
//                         d.body.appendChild(dForm); // generate form
//                         for (i = 0; i < len; i++) {
//                             var el = targetIdOrName.elements[i];
//                             var name = typeof(el.name) != ud ? el.name : null;
//                             var value = typeof(el.value) != ud ? el.value : null;
//                             tagName = el.nodeName.toLowerCase();
//                             if (tagName == 'input' && (el.type == 'radio' || el.type == 'checkbox') && !el.checked) {
//                                 name = null;
//                             } else if (tagName == 'select' && el.selectedIndex && el.selectedIndex != -1 && el.options[el.selectedIndex] && el.options[el.selectedIndex].value)
//                             {
//                                 value = el.options[el.selectedIndex].value
//                             };
//                             if (name != null && name != '') {
//                                 names.push(name);
//                                 values.push(el.value);
//                                 console.log("Element name: " + el.name + " / Element value: " + el.value);
//                                 params[name] = el.value;
//                             };
//                             addInput( dForm, el.name, el.value );
//                         }
//                         aoCAP.pid = 0;
//                         aoCAP.cuid = aoCAP.cuid || '';
//                         aoCAP.srcid = aoCAP.srcid || '';
//                         aoCAP.camp = aoCAP.camp || '';
//                         addInput( dForm, 'ao_a', aoArr[AoI].aid );
//                         addInput( dForm, 'ao_f', aoArr[AoI].fid );
//                         addInput( dForm, 'ao_d', aoArr[AoI].fid+":"+aoArr[AoI].did );
//                         addInput( dForm, 'ao_p', 0 );
//                         addInput( dForm, 'ao_jstzo', new Date().getTimezoneOffset() );
//                         addInput( dForm, 'ao_form_neg_cap', '' );
//                         addInput( dForm, 'ao_refurl', d.referrer );
//                         addInput( dForm, 'ao_cuid', aoCAP.cuid );
//                         addInput( dForm, 'ao_srcid', aoCAP.srcid );
//                         addInput( dForm, 'ao_camp', aoCAP.camp );
//                         bi(idform).submit();
//                         var dTargetFrame = bi( idifr );
//                         dTargetFrame.onload = function() {
//                             isLoaded = true;
//                         };
//                         var waitForSubmit = function()
//                         {
//                             this.count = "";
//                             if ( ! ( isLoaded || dTargetFrame.readyState == "complete" ) ) {
//                                 st( waitForSubmit, 200 );
//                                 this.count ++;
//                             } else if (this.count > 7) {
//                                 return true;
//                                 console.log("skipping dForm");
//                             }
//                             else {
//                                 d.body.removeChild( dForm );
//                                 d.body.removeChild( dTarget );
//                             }
//                         };
//                         st( waitForSubmit, 100 );
//                     }
//                 } else {
//                     console.log('aoCAP property missing');
//                 }
//             }
//         };
//         document.getElementById(formID).addEventListener("submit", function(evt) {
//             //alert('test');
//             evt.preventDefault();
//                 //evaluate radio buttons and post only if checked
//             var radio_btns = document.querySelectorAll("input[type='radio']");
//             for(var i = 0; i < radio_btns.length; i++){
//                 if(radio_btns[i].checked == false){
//                     radio_btns[i].setAttribute("name", "");
//                 };
//             };
//             //evaluate checkboxes and post only if checked
//             var chk_bxs = document.querySelectorAll("input[type='checkbox']");
//             for(var i = 0; i < chk_bxs.length; i++){
//                 if(chk_bxs[i].checked == false){
//                     chk_bxs[i].setAttribute("name", "");
//                 };
//             };
//             //if (formIsValid){
//             console.log("Submitting form!");
//             setTimeout(AoProcessForm(this), 0);
//             setTimeout(function(){
//                 //alert('test');
//                 if(window.location.pathname === "/contact/stay-informed") {
//                     window.location.replace("/contact/newsletter-thank-you")
//                 } else {
//                     window.location.replace("/contact/thank-you");
//                 }
//             }, 1000);
// //                     } else {
// //                         alert("Form invalid. Not submitted");
// //                     }
//         }, true);
//     }
  
    function initMap() {
        //This gets executed from maps embed callback

        // colours
        var darkgrey = '#3a363c';
        var lightgrey = '#e8e8e8';
        var whitesmoke = '#f5f5f5';
        var grey = '#5e5e5e';
        var blue = '#5fd4ff';
        var red = '#ff917f';
        var yellow = '#ffdc62';
        var green = '#00ffb0';
        var purple = '#c5a3ff';

        //location
        var motum = {lat: 43.653328, lng: -79.369760};

        //map and settings
        var map = new google.maps.Map(document.getElementById('motum-map'), {
            center: motum,
            zoom: 15,
            //styles
            styles: [
                {elementType: 'geometry', stylers: [{color: whitesmoke}]},
                {elementType: 'labels.text.stroke', stylers: [{color: whitesmoke}]},
                {elementType: 'labels.text.fill', stylers: [{color: red}]},
                {
                    featureType: 'administrative.locality',
                    elementType: 'labels.text.fill',
                    stylers: [{color: darkgrey}]
                },
                {
                    featureType: 'poi',
                    elementType: 'labels.text.fill',
                    stylers: [{color: darkgrey}]
                },
                {
                    featureType: 'poi.park',
                    elementType: 'geometry',
                    stylers: [{color: green}]
                },
                {
                    featureType: 'poi.park',
                    elementType: 'labels.text.fill',
                    stylers: [{color: grey}]
                },
                {
                    featureType: 'road',
                    elementType: 'geometry',
                    stylers: [{color: lightgrey}]
                },
                {
                    featureType: 'road',
                    elementType: 'geometry.stroke',
                    stylers: [{color: whitesmoke}]
                },
                {
                    featureType: 'road',
                    elementType: 'labels.text.fill',
                    stylers: [{color: '#9ca5b3'}]
                },
                {
                    featureType: 'road.highway',
                    elementType: 'geometry',
                    stylers: [{color: grey}]
                },
                {
                    featureType: 'road.highway',
                    elementType: 'geometry.stroke',
                    stylers: [{color: whitesmoke}]
                },
                {
                    featureType: 'road.highway',
                    elementType: 'labels.text.fill',
                    stylers: [{color: darkgrey}]
                },
                {
                    featureType: 'transit',
                    elementType: 'geometry',
                    stylers: [{color: yellow}]
                },
                {
                    featureType: 'transit.station',
                    elementType: 'labels.text.fill',
                    stylers: [{color: darkgrey}]
                },
                {
                    featureType: 'water',
                    elementType: 'geometry',
                    stylers: [{color: blue}]
                },
                {
                    featureType: 'water',
                    elementType: 'labels.text.fill',
                    stylers: [{color: grey}]
                },
                {
                    featureType: 'water',
                    elementType: 'labels.text.stroke',
                    stylers: [{color: blue}]
                },
                {
                    featureType: "poi",
                    stylers: [
                        { visibility: "off" }
                    ]
                }
            ]
        });
        var marker = new google.maps.Marker({
            position: motum,
            map: map,
            icon: '/images/interface/map-maker-m2.png'
        });
    }

    function initCountryOptin(){
        $(".js-details").hide();
        $("#country-input").change(function (){
            var countryOption = $(this).val();
            $(".js-details").hide();
            $("." + countryOption).show(); 
        })
    }


    function initThinkingSideContentSlideshow(){
        $('.thinking-side-content__slideshow').cycle({
            // fx: 'scrollVert',
            // fx: 'carousel',
            speed: 700,
            timeout: 0,
            // easing: 'easeInOutBack',
            next: '#next',
            prev: '#prev',
            delay: -2000,
            slides: '> div',
            // carouselVisible: 4,
            // carouselVertical: true
        });
    }

    function initProcessSVG(){
        //new Vivus('my-svg', {duration: 2200}, function(){});
    }

    function initSocialShareButtons(){
        var pageTitle = $('.page-header__title').text();
        var pageDescription = $('.page-header__subtitle').text();
        // Twitter
        $('.share-twitter').sharrre({
            share: {
                twitter: true
            },
            text: pageTitle,
            enableHover: false,
            enableTracking: true,
            template: '<i class="icon-twitter-x"></i>',
            buttons: { twitter: {}},
            click: function(api, options){
                api.simulateClick();
                api.openPopup('twitter');
            }
        });

        // Facebook
        $('.share-facebook').sharrre({
            share: {
                facebook: true
            },
            title: pageTitle,
            text: pageDescription,
            enableHover: false,
            enableTracking: false,
            enableCounter: false,
            template: '<i class="icon-facebook"></i>',
            buttons: { facebook: {}},
            click: function(api, options){
                api.simulateClick();
                api.openPopup('facebook');
            }
        });


        $('.share-linkedin a').on("click", function(e) {
            $(this).customerPopup(e);
        });



        /**
         * jQuery function to prevent default anchor event and take the href * and the title to make a share popup
         *
         * @param  {[object]} e           [Mouse event]
         * @param  {[integer]} intWidth   [Popup width defalut 500]
         * @param  {[integer]} intHeight  [Popup height defalut 400]
         * @param  {[boolean]} blnResize  [Is popup resizeabel default true]
         */
        $.fn.customerPopup = function (e, intWidth, intHeight, blnResize) {

            // Prevent default anchor event
            e.preventDefault();

            // Set values for window
            intWidth = intWidth || '500';
            intHeight = intHeight || '400';
            strResize = (blnResize ? 'yes' : 'no');

            // Set title and open popup with focus on it
            var strTitle = ((typeof this.attr('title') !== 'undefined') ? this.attr('title') : 'Social Share'),
                strParam = 'width=' + intWidth + ',height=' + intHeight + ',resizable=' + strResize,
                objWindow = window.open(this.attr('href'), strTitle, strParam).focus();
        }
    }

    function initClientListSlideshow(){
       setTimeout(function(){
           $('.client-list__slideshow').cycle({
               fx: 'zoom',
               sync:  false,
               delay: -2000,
               timeout: 5000,
               slides: '> div',
           });
       },1000);

    }

    function initClientTestimonialSlideshow(){

        $('.testimonial__slideshow').cycle({
            fx: 'scrollRight',
            sync:  false,
            timeout: 20000,
            slides: '> div',
            pager: '.slider-pager',
            pagerTemplate: '<button class="pager-btn"></button>',
            autoHeight: 'container'
        });
    }


    function initProjectViewCarousel(){
        $('.video-slideshow').cycle({
            timeout: 0,
            prev: '#prev',
            next: '#next',
            slides: "> span"
        });
        $('.image-slideshow').cycle({
            timeout: 0,
            speed: 200,
            prev: '#prev',
            next: '#next',
            slides: "> a, > span, > div"
        });
    }

    function initStickyBlogNav(){
        // Track all sections that have an `id` applied 
        document.querySelectorAll('.post-content [id]').forEach((section) => {
            blogObserver.observe(section);
        });
    }

    function removeStickyBlogNav(){
        if (blogObserver){
            blogObserver.disconnect()
        }
    }
    

    //for block video auto play
    function initPlayVideoOnScroll(){
        // Track all sections that have a `class` applied 
        //console.log('init Video');
        document.querySelectorAll('.post-content .video-block__video--play-on-scroll').forEach((video) => {
            //console.log(video);
            videoObserver.observe(video);
        });
    }

    function removePlayVideoOnScroll(){
        if (videoObserver){
            videoObserver.disconnect()
        }
    }
    

    // function initHomeVideo(){
    //     var vid = document.getElementById("intro-video");
    //     var $video = $('#intro-video');
    //     vid.oncanplaythrough = function() {
    //         $video.addClass('visible');
    //     };
    // }




    function resetFocusOnTeamSlider(person, source){

        $(".team-line__member[member-name]").each(function(){
            $(this).removeClass('active');
        })
        $(".team-list__name[member-name]").each(function(){
            $(this).removeClass('active');
        });

        $(`.team-list__name[member-name='${person}']`).addClass('active');
        $(`.team-line__member[member-name='${person}']`).addClass('active');

        if (window.innerWidth > 1024) {
            if (source === 'list'){
                $(`.team-list__name[member-name='${person}']`).focus();
            } 
        }
    }
    /////////
   


    //////////

    function initTeamPage(){
        function openPersonModalFromDeepLink(hash){
            let person = hash.substring(1);
            $(`#modal--${person}`).modal('show');
        }
    
        function addDeepLinkFromPersonModalOpen(modal){
            let person = modal.getAttribute('member-name');
            window.location.hash = person;
        }
    
        if (window.location.pathname === "/people-toronto-agency"){
            if (window.location.hash.length > 0) {
                openPersonModalFromDeepLink(window.location.hash);
            }
            $('.team-modal').on('shown.bs.modal', function (e) {
                addDeepLinkFromPersonModalOpen(this);
            })
            $('.team-modal').on('hide.bs.modal', function (e) {
                window.location.hash = '';
            })
        } 
        
        // Team line javascript     
        var activeMember = "";

        setTimeout(function(){
            $('.team-line').addClass('is-visible');
            setTimeout(() => {
                //A little hacky. Wait until after the library loads and overwrite the tab index.
                $('.team-line__scroll').attr('tabindex', "-1");
            }, 2000);
        

            //team toggle
            $('.team-toggle').click(function(){
                $('.team-list').slideToggle();
                $(this).toggleClass('active');
            });
            //end team toggle

            //edit the modals
            // $('.js-team-modal__exit').click(function(){
            //     $('.team-modal').fadeOut();
            // });

            //when hovering over the team member line add the class active to the team list
            // $(".team-line__member[member-name]").hover(function(){
            //     var memberName = $(this).attr('member-name');
            //     $('.team-list__name[member-name=' + memberName + ']').toggleClass('active');
            // });

            // //when hovering over the team member list add the class active to the team line
            // $(".team-list__name[member-name]").hover(function(){
            //     var memberName = $(this).attr('member-name');
            //     $('.team-line__member[member-name=' + memberName + ']').toggleClass('active');
            // });


            //List click and press is done via button modal attribute
            //Line click is done via div modal attribute
            //Line enter is done here via js 
         
            $(".team-line__member[member-name]").on('keypress', function(e){
                // console.log("key ", e.keyCode);
                if (e.keyCode == 13){
                    var memberName = $(this).attr('member-name');
                    $(`#modal--${memberName}`).modal('show');
                }
            });

            //when hovering over the team member line add the class active to the team list
            $(".team-line__member[member-name]").on("mouseenter focus", function(){
                var memberName = $(this).attr('member-name');
                if (memberName !== activeMember) {
                    activeMember = memberName;
                    resetFocusOnTeamSlider(activeMember, "line");
             
                }
                $('.team-list__name[member-name=' + memberName + ']').toggleClass('active');
            });

            //when hovering over the team member list add the class active to the team line
            $(".team-list__name[member-name]").on("mouseenter focus",function(){
                var memberName = $(this).attr('member-name');
                if (memberName !== activeMember) {
                    activeMember = memberName;
                    resetFocusOnTeamSlider(activeMember, "list");
                }
            });

            // //when clicking over the team member line pop up the modal
            // $(".team-line__member[member-name]").click(function(){
            //     //alert($(this).attr('member-name'));
            //     var memberName = $(this).attr('member-name');
            //     $('.team-modal[member-name=' + memberName + ']').fadeIn();
            // });

            // //when clicking over the team member list pop up the modal
            // $(".team-list__name[member-name]").click(function(){
            //     //alert($(this).attr('member-name'));
            //     var memberName = $(this).attr('member-name');
            //     $('.team-modal[member-name=' + memberName + ']').fadeIn();
            // });


            windowWidth = $(window).width();

            // console.log(windowWidth);
            if(windowWidth >= 1024){

                var pane =  $('.team-line__scroll');
                if (pane.length > 0){
                    pane.jScrollPane(
                        {
                            showArrows: true,
                            arrowScrollOnHover: true,
                            animateScroll: true,
                            arrowButtonSpeed: 100
                        }
                    );
                    var api = pane.data('jsp');
                    var startingScroll = ($('.team-line__member[member-name=lisa-kemerer]').position().left) - (windowWidth / 2.2);

                    api.scrollToX(startingScroll, 0);

                    //side scrolling for the line of humans
                    // Size the scroll container to items within
                    var navWidth = 0;
                    var memberOffset = convertRemToPixels(0);
                    $('.team-line__members > div').each(function( index ) {
                        navWidth = navWidth + ($(this).outerWidth() - memberOffset);
                        //console.log($(this).outerWidth());
                    });
                    $('.team-line__members').css('width', navWidth);


                    $(".team-list__name[member-name]").hover(function(){
                        var memberName = $(this).attr('member-name');
                        var navWidthHalf = (navWidth / 4);
                        var humanLocation = ($('.team-line__member[member-name=' + memberName + ']').position().left) - (windowWidth / 2);
                        api.scrollToX(humanLocation, 1500);
                    });




                    //if you resize the window it resizes the scroll blocks
                    var resizeThrottleTimeout;
                    $(window).bind(
                        'resize',
                        function()
                        {
                            if (!resizeThrottleTimeout) {
                                resizeThrottleTimeout = setTimeout(
                                    function()
                                    {
                                        api.reinitialise();
                                        resizeThrottleTimeout = null;
                                    },
                                    50
                                );
                            }
                        }
                    );
                }

            } //end of if window is wider than...
            else {
//                $('#team-line__Kemerer').css('order', -5);
//                $('#team-line__Whiting').css('order', -4);
//                $('#team-line__Lendt').css('order', -3);
//                $('#team-line__Dong').css('order', -2);
//                $('#team-line__Rhyno').css('order', -1);
            }

        }, 1000);
    }



    function initOurWorkIndex(){
        setTimeout(function(){

            // var support = { animations : Modernizr.cssanimations },
            //     animEndEventNames = { 'WebkitAnimation' : 'webkitAnimationEnd', 'OAnimation' : 'oAnimationEnd', 'msAnimation' : 'MSAnimationEnd', 'animation' : 'animationend' },
            //     animEndEventName = animEndEventNames[ Modernizr.prefixed( 'animation' ) ],
            //     onEndAnimation = function( el, callback ) {
            //         var onEndCallbackFn = function( ev ) {
            //             if( support.animations ) {
            //                 if( ev.target != this ) return;
            //                 this.removeEventListener( animEndEventName, onEndCallbackFn );
            //             }
            //             if( callback && typeof callback === 'function' ) { callback.call(); }
            //         };
            //         if( support.animations ) {
            //             el.addEventListener( animEndEventName, onEndCallbackFn );
            //         }
            //         else {
            //             onEndCallbackFn();
            //         }
            //     };

            // from http://www.sberry.me/articles/javascript-event-throttling-debouncing
            function throttle(fn, delay) {
                var allowSample = true;

                return function(e) {
                    if (allowSample) {
                        allowSample = false;
                        setTimeout(function() { allowSample = true; }, delay);
                        fn(e);
                    }
                };
            }

            // sliders - flickity
            var grid = document.querySelector('.grid'),
                // isotope instance
                iso,
                // filter ctrls
                filterCtrls = [].slice.call(document.querySelectorAll('.work-filter'));

            function init() {
                // preload images
                initIsotope();
                initEvents();
                classie.remove(grid, 'grid--loading');
                $('.our-work-cards').addClass('hover');
                $('.our-work-card:first-child').addClass('is-hovering');

            }

            function initIsotope() {
                iso = new Isotope( grid, {
                    isResizeBound: false,
                    itemSelector: '.grid__item',
                    //percentPosition: true,
                    layoutMode: 'packery',

//                masonry: {
//                    // use outer width of grid-sizer for columnWidth
//                    columnWidth: '.grid__sizer'
//                },
                    // transitionDuration: '0.6s'
                });

            }

            function initEvents() {
                filterCtrls.forEach(function(filterCtrl) {
                    filterCtrl.addEventListener('click', function() {
                        classie.remove(filterCtrl.parentNode.querySelector('.work-filter--selected'), 'work-filter--selected');
                        classie.add(filterCtrl, 'work-filter--selected');
                        iso.arrange({
                            filter: filterCtrl.getAttribute('data-filter')
                        });
                        //recalcFlickities();
                        iso.layout();
                    });
                });

                // window resize / recalculate sizes for both flickity and isotope/masonry layouts
                window.addEventListener('resize', throttle(function(ev) {
                    //recalcFlickities()
                    iso.layout();
                }, 50));


            }


            init();

        }, 1000);
    }


    function initOurWorkCard(){
        $('.our-work-card').hover(function(){
            $(this).addClass('is-hovering');
            $('.our-work-cards').addClass('hover');
        }, function(){
            $(this).removeClass('is-hovering');
            $('.our-work-cards').removeClass('hover');
        });
    }


    // Determine Mouse or Keyboard interaction
    // assume mouse by default 
    document.body.classList.add('using-mouse');
	document.body.addEventListener('mousedown', function() {
		document.body.classList.add('using-mouse');
	}); 
	document.body.addEventListener('keydown', function(event) {
		if (event.key === 'Tab' || event.key === 'Space') {
			document.body.classList.remove('using-mouse');
		}
	});




    var pageToRemove;
    var targetName;

    var $loading = $('.loading-animation');

    var activeTransition;
    var controller;
    var action;

    var windowWidth = $(window).width();

    //afterPageFunctions();

    function beforeFunc(){
        console.log("before");
    }

    function afterFunc(){
        console.log("after");
    }

});



//convert px into rems
function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}